import React from "react"
import styled from "styled-components"

const Paragraph = ({ text }) => {
  return <Text dangerouslySetInnerHTML={{ __html: text.html }} />
}

export default Paragraph

const Text = styled.div`
  p {
    padding-bottom: 1rem;
    color: var(--primary-text);
    font-family: "Moderat Regular", sans-serif;
  }
  ul,
  li,
  a:visited {
    color: var(--primary-text);
  }
  iframe {
    width: 100%;
    height: 340px;
    padding: 1rem 0;
  }
`
