import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const ArticleItem = ({ article }) => {
  const title = article.data.article_title.text
  return (
    <StyledLink to={`/articles/${article.uid}`}>
      <Container>
        <Test
          fluid={article.data.article_image.fluid}
          alt={article.data.article_image.alt || "article"}
        />
        <Text color="#fff">{title}</Text>
      </Container>
    </StyledLink>
  )
}

export default ArticleItem

const Container = styled.div`
  .gatsby-image-wrapper {
  }
`
const Test = styled(BackgroundImage)`
  height: 303px;
`
const StyledLink = styled(Link)`
  text-decoration: none;
`

const Text = styled.p`
  color: #fff;
  line-height: 1.2rem;
  max-width: 360px;
  padding: 1rem 0;
`
