import React from "react"
import styled from "styled-components"

const Heading = ({ article_heading }) => {
  return <TextHeading>{article_heading.text}</TextHeading>
}

export default Heading

export const TextHeading = styled.h2`
  color: ${({ color }) => color || "var(--primary-text)"};
  font-family: "Moderat Regular", sans-serif;
  margin: 0 0 2rem 0;
  font-style: italic;
  font-size: 25pt;
  line-height: 24pt;
  font-weight: 100;
`
