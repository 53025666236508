import React from "react"
import styled from "styled-components"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import Layout from "../layout/layout"
import SEO from "../seo"
import ContactForm from "../molecules/contactForm"

const VacancyPage = ({ data }) => {
  const { uid, data: vacancyData } = data.prismicVacancy
  const bannerData = vacancyData.body[0]
    ? vacancyData.body[0].primary
    : undefined
  const { date, department } = vacancyData
  const formattedDate = date?.replace(/-/gi, " ")
  return (
    <Layout bookBannerData={bannerData}>
      <SEO title={uid} />
      <Container>
        <Title>{vacancyData.title}</Title>
        {!date && !department ? null : (
          <VacancyDetails>
            <VacancyEntry>
              <Info>Department:</Info>
              <Text>{vacancyData.department}</Text>
            </VacancyEntry>
            <VacancyEntry>
              <Info>Date:</Info>
              <Text>{formattedDate}</Text>
            </VacancyEntry>
          </VacancyDetails>
        )}
        <Description
          dangerouslySetInnerHTML={{
            __html: vacancyData.vacancy_description.html,
          }}
        />
        <ContactForm formType={"vacancy"} hasFileUpload={true} />
      </Container>
    </Layout>
  )
}

export default withPreview(VacancyPage)

const Container = styled.div`
  max-width: 700px;
  h1 {
    font-family: "Canela Light", sans-serif;
    font-size: 4rem;
    margin: 1rem 0 1rem 0;

    color: var(--primary-text);
  }
  padding: 40px 0 16px 0;
  color: var(--primary-text);
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-gap: 16px;
`
const VacancyDetails = styled.div`
  padding: 16px 0;
`
const VacancyEntry = styled.div`
  display: grid;
  margin-bottom: 24px;
`
const Title = styled.h1`
  text-align: left;
`
const Info = styled.p`
  text-align: left;
  font-family: "Moderat Bold", sans-serif;
  margin-bottom: 8px;
`
const Text = styled.p`
  text-align: left;
`

const Description = styled.div`
  line-height: 2rem;
  padding: 0 0 1rem 0;
  h1,
  h2 {
    //text-align: left;
    //font-size: 2rem;
  }
  a {
    width: max-content;
    text-decoration: none;
    color: var(--primary-text);
    position: relative;
    transition: width 300ms, transform 300ms;
    :hover {
      color: var(--primary-blue);
    }
    ::after {
      transition: width 300ms, transform 300ms;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: var(--primary-text);
    }
    :hover::after {
      transition: width 300ms, transform 300ms;
      width: 100%;
    }
  }
`

export const query = graphql`
  query ($uid: String!) {
    prismicVacancy(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        date(formatString: "D-MMMM-YYYY")
        department
        title
        vacancy_description {
          html
        }
        body {
          ... on PrismicVacancyBodyBook {
            id
            primary {
              text {
                text
              }
              link {
                url
                link_type
              }
              button_text
            }
          }
        }
      }
    }
  }
`
