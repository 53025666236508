import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Paragraph from "./paragraph"

const Image = ({ text_image, image_text }) => {
  return (
    <Container>
      <ImageContainer>
        <Img fluid={text_image.fluid} alt={text_image.alt || "article"} />
      </ImageContainer>
      <Paragraph text={image_text} />
    </Container>
  )
}

export default Image

const Container = styled.div`
  padding: 0 0 40px 0;
`
const Text = styled.div`
  color: var(--primary-text);
`
const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    height: 450px;
    margin: 40px auto 40px auto;
    max-width: 600px;
  }
`
