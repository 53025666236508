import React from "react"
import Img from "gatsby-image"
import Layout from "../layout/layout"
import SEO from "../seo"
import BookNowBanner from "../home/book"
import Heading from "../article/heading"
import Image from "../article/Image"
import styled from "styled-components"
import Paragraph from "../article/paragraph"
import MoreArticles from "../article/moreArticles"
import ArticleVideo from "../article/ArticleVideo"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

const sectionMapper = {
  heading: Heading,
  text: Paragraph,
  image: Image,
  book: BookNowBanner,
  video: ArticleVideo,
}

const ArticlePage = props => {
  const { uid, data: articleData } = props.data.prismicArticle
  let noteSection
  let bookSection
  let articlesSection
  const sections = []
  articleData.body.forEach(section => {
    if (section.slice_type === "note") {
      noteSection = section
    } else if (section.slice_type === "book") {
      bookSection = section
    } else if (section.slice_type === "more") {
      articlesSection = section
    } else {
      sections.push(section)
    }
  })
  const note = noteSection ? (
    <Note dangerouslySetInnerHTML={{ __html: noteSection.primary.note.html }} />
  ) : null

  return (
    <Layout
      bookBannerData={bookSection ? { ...bookSection.primary } : undefined}
    >
      <SEO title={uid} />
      <Container>
        <TitleContainer>
          <Title
            dangerouslySetInnerHTML={{ __html: articleData.article_title.html }}
          />
        </TitleContainer>
        <ImageContainer>
          <Img
            fluid={articleData.article_image.fluid}
            alt={articleData.article_image.alt}
          />
        </ImageContainer>
        <ContentContainer>
          <Content>
            {sections.map((section, i) => {
              const Component = sectionMapper[section.slice_type]
              return <Component key={i} {...section.primary} />
            })}
          </Content>
          {note}
        </ContentContainer>
      </Container>
      {articlesSection ? <MoreArticles {...articlesSection.primary} /> : null}
    </Layout>
  )
}

export default withPreview(ArticlePage)

const Container = styled.div`
  display: grid;
  width: 80%;
  margin: 0 auto;
`
const ContentContainer = styled.div`
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr 600px 1fr;
  grid-template-areas: ". content note";
  grid-gap: 80px;
  @media (max-width: 1250px) {
    grid-template-columns: 1fr 450px 1fr;
    grid-gap: 40px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-areas: "note" "content";
  }
`

const Content = styled.div`
  grid-area: content;
`
const TitleContainer = styled.div`
  padding: 1rem 0;
`
const Title = styled.div`
  h1 {
    font-family: "Canela Light", sans-serif;
    font-size: 3.6rem;
    color: var(--primary-text);
    margin: 0;
    text-align: left;
    @media (max-width: 600px) {
      font-size: 3rem;
    }
  }
`
const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    max-height: 450px;
    min-height: 300px;
  }
`
const Note = styled.div`
  grid-area: note;
  p {
    font-style: italic;
    line-height: 1.2rem;
    padding-bottom: 1rem;
    color: var(--primary-text);
    font-family: "Moderat Regular", sans-serif;
  }
`

export const query = graphql`
  query Article($uid: String!) {
    prismicArticle(uid: { eq: $uid }) {
      _previewable
      uid
      id
      data {
        article_title {
          html
          text
          raw
        }
        article_image {
          alt
          fluid {
            aspectRatio
            sizes
            src
            srcSet
          }
        }
        body {
          ... on PrismicArticleBodyHeading {
            id
            slice_type
            primary {
              article_heading {
                text
                html
              }
            }
          }
          ... on PrismicArticleBodyText {
            id
            slice_type
            primary {
              text {
                text
                html
              }
            }
          }
          ... on PrismicArticleBodyImage {
            id
            slice_type
            primary {
              image_text {
                text
              }
              text_image {
                fluid {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
                alt
              }
            }
          }
          ... on PrismicArticleBodyMore {
            id
            slice_type
            primary {
              title {
                text
                html
              }
              background_image {
                alt
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          ... on PrismicArticleBodyNote {
            id
            slice_type
            primary {
              note {
                text
                html
              }
            }
          }
          ... on PrismicArticleBodyVideo {
            id
            slice_type
            primary {
              video_url {
                url
              }
            }
          }
          ... on PrismicArticleBodyBook {
            id
            slice_type
            primary {
              text {
                text
              }
              link {
                url
              }
              button_text
            }
          }
        }
      }
    }
  }
`
