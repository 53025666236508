import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import ArticleItem from "./articleItem"
import PreviewWarning from "../../atoms/userFeedback/PreviewWarning"
import { graphql, useStaticQuery } from "gatsby"

const MoreArticles = props => {
  if (!props.background_image.fluid) {
    return <PreviewWarning section="More articles" />
  }
  const { allPrismicArticle } = useStaticQuery(graphql`
    query {
      allPrismicArticle {
        nodes {
          uid
          _previewable
          data {
            article_image {
              alt
              url
              fluid {
                src
                aspectRatio
                srcSet
                sizes
              }
              fixed(height: 303) {
                width
                height
                src
                srcSet
              }
            }
            article_title {
              text
              html
            }
          }
        }
      }
    }
  `)
  const moreArticles = allPrismicArticle
    ? allPrismicArticle.nodes
        .filter(article => article.uid !== "placeholder")
        .slice(0, 3)
    : []
  return (
    <Container fluid={props.background_image.fluid}>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: props.title.html }} />
        <ArticlesContainer>
          {moreArticles.map((article, i) => (
            <ArticleItem key={i} article={article} />
          ))}
        </ArticlesContainer>
      </Content>
    </Container>
  )
}

export default MoreArticles

const Container = styled(BackgroundImage)``
const Content = styled.div`
  width: 80%;
  padding: 80px 0;
  margin: 40px auto 0 auto;
`
const Title = styled.div`
  h3 {
    font-family: "Moderat Bold", sans-serif;
    color: var(--primary-white);
  }
`
export const ArticlesContainer = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: space-between;
  grid-gap: 24px;
`
