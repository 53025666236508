import React from "react"
import { withUnpublishedPreview } from "gatsby-source-prismic"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageHeader from "../components/pageHeader"
import ArticlePage from "../components/templates/articlePage"
import VacancyPage from "../components/templates/vacancyPage"
import RoomPage from "../components/templates/roomPage"
import ContentPage from "../components/templates/contentPage"

const NotFoundPage = ({ data: previewData }) => {
  const popupPreview = previewData ? previewData : undefined

  return (
    <Layout popupPreviewData={popupPreview} bookBannerData={undefined}>
      <SEO title="Page not found" />
      <PageHeader
        title={{ html: "<h1>404: Not Found</h1>" }}
        tagline={{
          text: "You just hit a route that doesn't exist...",
        }}
      />
    </Layout>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    article: ArticlePage,
    vacancy: VacancyPage,
    room: RoomPage,
    content_template: ContentPage,
  },
})
