import React from "react"
import styled from "styled-components"

const ArticleVideo = ({ video_url }) => {
  const url = video_url.url
  const videoId = url.slice(url.lastIndexOf("=") + 1)
  return <Iframe src={`https://www.youtube.com/embed/${videoId}`}></Iframe>
}

export default ArticleVideo

const Iframe = styled.iframe`
  margin: 40px auto;
  width: 100%;
  border: none;
  display: block;
  height: 350px;
  @media (max-width: 1024px) {
    height: 300px;
  }
`
